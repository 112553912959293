/**
 * This is an abstract class that serves as a base for all initializer classes.
 * It declares two abstract methods: start and init.
 */
abstract class BaseInitializer {
  public abstract start(): void;

  protected abstract init(): void;
}

export default BaseInitializer;
