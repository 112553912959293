import React, { useEffect, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { Provider as StyletronProvider } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { BaseProvider } from "baseui";
import "./App.scss";
import store from "./store";
import { TechemTheme } from "@techem/techem-theme";
import {
  StyletronEngineProvider,
  ThemeContextProvider,
} from "@techem/ui-components";
import { loadAdobeAnalytics } from "tracking/adobe";
import Routes from "./Routes";
import TrackingService from "./tracking/TrackingService";

const styletronEngine = new Styletron({
  // ensure compatibility with other frameworks like bootstrap by prefixing class names, etc.
  prefix: "st-",
});

const App = () => {
  const [initDone, setInitDone] = useState<boolean>(false);

  useEffect(() => {
    const initializeGlobal = async () => {
      try {
        const { loadTrackingScripts, setupTracking, trackPage } =
          TrackingService();

        setupTracking();

        await loadAdobeAnalytics();
        await loadTrackingScripts();

        trackPage();

        // The following promises need to be resolved before rendering the application,
        // because the application is relying on global functions and variables provided by them.
        loadAdobeAnalytics();

        setInitDone(true);
      } catch (err) {
        console.error("Client side global js init failed", err);
      }
    };

    initializeGlobal().then(() => {
      console.info("Global initialization done.");
    });
  }, []);

  return initDone ? (
    <ReduxProvider store={store}>
      <ThemeContextProvider value={TechemTheme}>
        <BaseProvider theme={TechemTheme}>
          <StyletronProvider value={styletronEngine}>
            <StyletronEngineProvider value={styletronEngine}>
              <RouterProvider router={Routes} />
            </StyletronEngineProvider>
          </StyletronProvider>
        </BaseProvider>
      </ThemeContextProvider>
    </ReduxProvider>
  ) : null;
};
export default App;
